import styled from 'styled-components';

import { Box, Icon, MultiStepReturnType, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { capitalizeWords } from '@hl-portals/helpers';

import { useScreenSize } from '@hl-portals/hooks';

type RequestAgreementSidebarProps = {
  property: string;
} & { multiStep: MultiStepReturnType };

const Flipped = styled(Box)`
  transform: rotate(180deg);
`;

export const RequestAgreementSidebar = ({
  property,
  multiStep,
}: RequestAgreementSidebarProps) => {
  const { isDesktop } = useScreenSize();

  return (
    <Box
      flexDirection="column"
      bgcolor={{
        xs: multiStep.isFirstStep ? '#EFFAF6' : theme.colors.white,
        md: '#EFFAF6',
      }}
      p={{ xs: multiStep.isFirstStep ? '32px 16px' : '16px', md: '80px' }}
      maxWidth={{ xs: 'auto', md: '600px' }}
      minHeight={{ xs: 'auto', md: 'calc(100vh - 67px)' }}
      borderBottom={{ xs: `1px solid ${theme.colors.coolGray4}`, md: 'none' }}
    >
      {isDesktop ? (
        <Box flexDirection="column" mt="100px">
          <Paragraph variant="heading-2" fontSize={{ xs: '24px', md: '36px' }}>
            Request
          </Paragraph>
          <Paragraph
            variant="heading-2"
            mb="12px"
            fontSize={{ xs: '24px', md: '36px' }}
          >
            Buy Before You Sell Agreement
          </Paragraph>
          <Paragraph lineHeight="180%" fontSize={{ xs: '16px', md: '20px' }}>
            for {capitalizeWords(property.toLowerCase())}
          </Paragraph>
        </Box>
      ) : multiStep.isFirstStep ? (
        <Box flexDirection="column">
          <Paragraph variant="heading-2" fontSize={{ xs: '24px', md: '36px' }}>
            Request Buy Before You Sell Agreement
          </Paragraph>
          <Paragraph lineHeight="180%" fontSize={{ xs: '16px', md: '20px' }}>
            for {capitalizeWords(property.toLowerCase())}
          </Paragraph>
        </Box>
      ) : (
        <Box position="relative" justifyContent="center">
          <Flipped position="absolute" left="16px" onClick={multiStep.goBack}>
            <Icon type="arrowRight" />
          </Flipped>
          <Paragraph variant="text-small" fontWeight="700">
            Request Agreement
          </Paragraph>
        </Box>
      )}
    </Box>
  );
};
