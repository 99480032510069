import { Box, Logo, Paragraph } from '@hl-portals/ui';

export const BBYSLogo = ({ variant, ...props }) => (
  <Box flexDirection="column" {...props}>
    <Paragraph variant={variant}>Buy Before You Sell</Paragraph>
    <Box gap="4px" alignItems="center">
      <Paragraph variant="text-xsmall" color="gray700">
        Powered by
      </Paragraph>
      <Logo width="77px" variant="horizontal" />
    </Box>
  </Box>
);
