import { PropsWithChildren } from 'react';

import { useRouter } from 'next/router';

import { Box, FadeIn, Icon, Logo } from '@hl-portals/ui';

import { GenericLoading } from './GenericLoading';
import { Loading } from './Loading';

type ContainerProps = {
  isLoading?: boolean;
  bgColor?: string;
  showGenericLoading?: boolean;
  onClose?: () => void;
};

export const Container = ({
  children,
  isLoading,
  showGenericLoading,
  onClose,
  bgColor = 'white',
}: PropsWithChildren<ContainerProps>) => {
  const router = useRouter();

  return (
    <>
      <Box
        width="100%"
        height="67px"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="white"
        borderBottom="1px solid #DBDFE6"
        p={{ xs: '0 16px', md: '0 80px' }}
      >
        <Logo
          variant="horizontal"
          onClick={() => router.push('/')}
          cursor="pointer"
        />
        {onClose && <Icon type="closeSlim" onClick={onClose} />}
      </Box>

      <Box
        width="100%"
        minHeight="calc(100vh - 67px)"
        maxWidth="2400px"
        margin="0 auto"
        flexDirection="column"
        bgcolor={bgColor}
      >
        {isLoading ? (
          <FadeIn>
            {showGenericLoading ? <GenericLoading /> : <Loading />}
          </FadeIn>
        ) : (
          <FadeIn
            height="100%"
            flexGrow="1"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="center"
          >
            {children}
          </FadeIn>
        )}
      </Box>
    </>
  );
};
