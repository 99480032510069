import html2canvas from 'html2canvas';
import styled from 'styled-components';

import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  FadeIn,
  Icon,
  Paragraph,
  ResponsiveDisplay,
  SimpleLink,
} from '@hl-portals/ui';

// import '@hl-portals/ui';
import { theme } from '@hl-portals/constants';

import { formatCurrency } from '@hl-portals/helpers';

import { CalculateEquityData, useScreenSize } from '@hl-portals/hooks';

import { useUser } from '../../../contexts/Auth';
import { InstantApprovalBBYSLogo } from './InstantApprovalBBYSLogo';
import { InstantApprovalEucReport } from './InstantApprovalEucReport';
import type { PropertyInfo } from './types';

const TransparentButton = styled(Button)`
  background: transparent;
`;

type ResultProps = {
  flavor: 'agent' | 'lender';
  propertyInfo: PropertyInfo;
  equityData: CalculateEquityData;
  goBack: () => void;
};

const lowEndHighEndRange = ({
  low_end_range,
  high_end_range,
}: {
  low_end_range: number;
  high_end_range: number;
}) => {
  if (high_end_range <= 0) {
    return formatCurrency({ value: 0 });
  }

  const low_range_value = low_end_range < 0 ? 0 : low_end_range;
  return `${formatCurrency({ value: low_range_value })} - ${formatCurrency({
    value: high_end_range,
  })}`;
};

export const InstantApprovalEucResult = ({
  flavor = 'agent',
  propertyInfo,
  equityData,
  goBack,
}: ResultProps) => {
  const { isMobile } = useScreenSize();
  const printRef = useRef<HTMLDivElement | null>(null);
  const [downloading, setDownloading] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = flavor == 'agent' ? useUser() : { user: null };
  const { fallback } = equityData;
  const onDownloadClick = () => {
    setDownloading(true);
  };

  const downloadImage = async () => {
    const canvas = await html2canvas(printRef.current);
    const link = canvas.toDataURL('image/png', 1.0);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = link;
    a.download = 'estimated_equity_unlock_range.png';
    a.click();
    setDownloading(false);
  };

  useEffect(() => {
    if (downloading) {
      downloadImage();
    }
  }, [downloading]);

  const { high_end_range, low_end_range } = equityData;
  const lowHighRange = lowEndHighEndRange({
    low_end_range: low_end_range,
    high_end_range: high_end_range,
  });

  return (
    <FadeIn flexDirection="column" flexGrow="1">
      <Box flexDirection="column" alignItems="center">
        <Box
          py="40px"
          px={{ xs: '0', md: '40px' }}
          maxWidth={{ xs: '100%', md: '704px' }}
          flexDirection="column"
          flexGrow="1"
          ref={printRef}
        >
          {fallback && (
            <Box m="16px 16px 0" bgcolor={theme.colors.darkBlue}>
              <Paragraph variant="text-small" color="white" p="16px">
                We couldn't find information on the property, the range is based
                solely on the Agent's Home Value Estimate.
              </Paragraph>
            </Box>
          )}

          <Box ml="10px">
            <SimpleLink onClick={() => goBack()} display="flex" color="#273653">
              <Icon mr="8px" type="angleLeft" />
              <ResponsiveDisplay
                desktop="Back to Equity Unlock Calculator"
                mobile="Back"
              />
            </SimpleLink>
          </Box>

          <Box flexDirection="column" p="16px">
            <Box
              flexDirection="column"
              borderRadius="8px"
              border={`1px solid ${theme.colors.coolGray5}`}
              bgcolor="white"
              overflow="hidden !important"
              mb="24px"
            >
              <Box
                bgcolor={theme.colors.lightBlue}
                flexDirection="column"
                p="40px 32px 32px 32px"
                position="relative"
              >
                <Box justifyContent="space-between" alignItems="top">
                  <Box flexDirection="row" gap="10px">
                    <Box flexDirection="column" maxWidth="305px">
                      <InstantApprovalBBYSLogo
                        variant="heading-3"
                        mb={{ xs: '24px', md: '24px' }}
                        fontSize={{ xs: '24px', md: '30px' }}
                        fontWeight="600"
                      />
                      <Paragraph
                        variant="text-xsmall"
                        mt="24px"
                        textTransform="uppercase"
                      >
                        Equity Unlock Range:
                      </Paragraph>
                      <Paragraph
                        fontSize="32px"
                        variant={isMobile ? 'heading-3' : 'heading-2'}
                        mb="12px"
                        data-test="equity-range"
                      >
                        {lowHighRange}
                      </Paragraph>
                      <Paragraph variant="text" mt="8px">
                        Estimation of how much equity your client can unlock
                        upfront— to get a final amount submit client.
                      </Paragraph>
                    </Box>
                    <Box
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Box position="absolute" top="40px" right="36px">
                        <TransparentButton
                          display={downloading ? 'none' : 'flex'}
                          color="secondary"
                          size="small"
                          alignItems="center"
                          onClick={() => onDownloadClick()}
                          p={{ xs: '8px 16px', md: '0 16px' }}
                          data-test="equity-download-btn"
                        >
                          <Icon
                            mr={{ xs: '0px', md: '8px' }}
                            type="downloadLine"
                            fill="electricBlue"
                          />
                          <ResponsiveDisplay
                            desktop="Download Range"
                            mobile={null}
                          />
                        </TransparentButton>
                      </Box>
                      <Box
                        position="absolute"
                        bottom="0"
                        right="36px"
                        display={{ xs: 'none', sm: 'inherit' }}
                      >
                        <img src="/illustrations/house.png" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box p="24px" flexDirection="column">
                <InstantApprovalEucReport
                  flavor={flavor}
                  user={user}
                  propertyInfo={propertyInfo}
                  equityData={equityData}
                />
              </Box>
            </Box>
            <Box flexDirection="column" gap="8px">
              <Paragraph color="coolGray2" variant="text-xsmall">
                This range is meant to be a guide/estimate of the equity unlock
                amount based off of your input and our system’s initial review
                of your property. This is not a commitment to lend and is not a
                formal approval. Final numbers will be provided after further
                review and additional information is gathered. Minimums may
                apply.
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>
    </FadeIn>
  );
};
