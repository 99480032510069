import { PropsWithChildren } from 'react';

import {
  Box,
  Icon,
  MultiStepReturnType,
  Paragraph,
  Timeline,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useScreenSize } from '@hl-portals/hooks';

import { InstantApprovalBBYSLogo } from './InstantApprovalBBYSLogo';

const LogoWithDescription = () => (
  <>
    <InstantApprovalBBYSLogo
      variant="heading-3"
      mb={{ xs: '24px', md: '40px' }}
      fontSize={{ xs: '24px', md: '30px' }}
      fontWeight="600"
    />
    <Paragraph
      variant="heading-2"
      mb="12px"
      fontSize={{ xs: '24px', md: '30px' }}
    >
      Equity Unlock Calculator
    </Paragraph>
    <Paragraph lineHeight="180%">
      Verify your client's program eligibility and estimate how much equity we
      can unlock.
    </Paragraph>
  </>
);

export const InstantApprovalSidebar = ({
  multiStep,
  children,
}: PropsWithChildren & { multiStep: MultiStepReturnType }) => {
  const { isDesktop } = useScreenSize();

  return (
    <Box
      flexDirection="column"
      bgcolor={theme.colors.mistBlue}
      p={{ xs: '32px 16px', md: '80px' }}
      flex={{ xs: '0', md: '.75' }}
      maxWidth={{ xs: 'auto', md: '600px' }}
      minHeight={{ xs: 'auto', md: 'calc(100vh - 67px)' }}
    >
      {isDesktop ? (
        <Box flexDirection="column">
          <LogoWithDescription />
          <Box mt="40px">
            <Timeline.Root>
              {multiStep.steps.map((step, i) => (
                <Timeline.Item
                  key={step}
                  isActive={i <= multiStep.currentStepIndex}
                >
                  <Timeline.Marker>
                    {multiStep.isPastStep(step) ? (
                      <Icon size={12} type="check" color="white" />
                    ) : (
                      <Timeline.Marker>{i + 1}</Timeline.Marker>
                    )}
                  </Timeline.Marker>
                  <Timeline.Label>{step}</Timeline.Label>
                </Timeline.Item>
              ))}
            </Timeline.Root>
          </Box>
        </Box>
      ) : (
        <Timeline.Root>
          {multiStep.isFirstStep ? (
            <LogoWithDescription />
          ) : (
            <>
              <Paragraph color="gray600">
                Step {multiStep.currentStepIndex + 1} of{' '}
                {multiStep.steps.length}
              </Paragraph>

              <Paragraph variant="heading-4" mt="24px">
                {multiStep.currentStep}
              </Paragraph>
            </>
          )}
        </Timeline.Root>
      )}
      {children && <Box>{children}</Box>}
    </Box>
  );
};
