import { Box, Button, Logo, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { formatCurrency } from '@hl-portals/helpers';

import { CalculateEquityData, useScreenSize } from '@hl-portals/hooks';

import type { Assumption } from './Assumption';
import { AssumptionItem } from './Assumption';
import { PropertyInfo } from './types';

type ReportProps = {
  flavor: 'agent' | 'lender';
  user?: any;
  propertyInfo: PropertyInfo;
  equityData: CalculateEquityData;
};

export const InstantApprovalEucReport = ({
  flavor,
  user,
  propertyInfo,
  equityData,
}: ReportProps) => {
  const {
    address,
    unit,
    city,
    state,
    postalCode,
    estimatedHomeValue,
    estimatedMortgageBalance,
  } = propertyInfo;

  const { isMobile } = useScreenSize();

  const assumptions: Assumption[] = [
    {
      label: 'Property Address',
      value: (
        <Box flexDirection={{ xs: 'column', md: 'row' }}>
          <Paragraph variant="text-small" mr={{ xs: '0', md: '4px' }}>
            {address}
            {unit ? `, #${unit}` : ''},
          </Paragraph>
          <Paragraph variant="text-small">
            {city}, {state} {postalCode}
          </Paragraph>
        </Box>
      ),
    },
    {
      label:
        flavor == 'lender'
          ? "Lender's Estimated Home Value"
          : "Agent's Estimated Home Value ",
      value: <>{formatCurrency({ value: estimatedHomeValue })}</>,
    },
    {
      label: 'Estimated Mortgage Balance',
      value: (
        <>
          {formatCurrency({
            value: equityData.estimated_loan_payoff || estimatedMortgageBalance,
          })}
        </>
      ),
      description:
        estimatedMortgageBalance || equityData.estimated_loan_payoff
          ? `Value provided by ${
              estimatedMortgageBalance || equityData.estimated_loan_payoff
                ? flavor == 'lender'
                  ? 'Lender'
                  : user?.agent?.full_name
                : 'our data services'
            }`
          : 'Assuming property is free and clear.',
    },
  ];

  return (
    <Box flexDirection="column" gap="16px" width="100%">
      <Paragraph variant="text-small-bold">Assumptions:</Paragraph>
      {assumptions.map((assumption) => (
        <AssumptionItem key={assumption.label} {...assumption} />
      ))}

      <Box
        border={`1px solid ${theme.colors.coolGray4}`}
        borderRadius="16px"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap="16px"
        width="100%"
        mt="36px"
        p="16px 8px"
      >
        {!isMobile && (
          <Box alignItems="center" marginX="8px">
            <Logo variant="addClient" />
          </Box>
        )}

        <Box flexDirection="column" maxWidth="300px" pl="8px">
          <Paragraph variant="heading-5" mb="8px">
            Ready for the next step?
          </Paragraph>
          <Paragraph variant="text">
            Submit a client application to get a final equity amount with no
            cost or commitment.{' '}
          </Paragraph>
        </Box>
        <Box alignItems="center">
          <Button
            as="a"
            href="https://homelight.typeform.com/to/fL8hhQKc#task_id=xxxxx"
            size={{ xs: 'large', md: 'small' }}
            width={{ xs: '100%', md: 'auto' }}
            data-test="client-submit-btn"
            fontSize="16px"
          >
            Go to Application
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
