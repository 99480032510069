import { Box, Button, Paragraph } from '@hl-portals/ui';

type WarningModalProps = {
  onAccept: () => void;
  onDecline: () => void;
};

export const WarningModal = ({ onAccept, onDecline }: WarningModalProps) => (
  <Box flexDirection="column" p="56px 24px 24px 24px">
    <Box flexDirection="column" width="448px">
      <Paragraph variant="heading-3" mb="8px">
        Do you want to cancel submission?
      </Paragraph>
      <Paragraph mb="24px">
        Changes you made so far will not be saved.
      </Paragraph>
    </Box>

    <Box gap="12px">
      <Button color="tertiary" size="large" onClick={onDecline}>
        Continue submission
      </Button>
      <Button color="urgent" size="large" onClick={onAccept}>
        Yes, cancel submission
      </Button>
    </Box>
  </Box>
);
