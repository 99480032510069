import { useFlags } from 'flagsmith/react';

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BoxTypes, Icon, Inputs, Paragraph, SlideDown } from '@hl-portals/ui';

const DefaultLabel = () => (
  <Paragraph variant="text-small">
    How much equity does your client need to unlock from their current property?
    <br />
    <Paragraph as="span" variant="text-small" color="gray600">
      (Target Equity Unlock Amount)
    </Paragraph>
  </Paragraph>
);

type TargetEquityInputProps = {
  label?: React.ReactNode;
  testId?: string;
  inputTestId?: string;
  name: string;
} & BoxTypes;

export const TARGET_AMOUNT_RADIO_NAME = 'targetAmountRadio';

export const TargetEquityInput = ({
  label,
  name,
  ...props
}: TargetEquityInputProps) => {
  const [focus, setFocus] = useState(false);
  const { watch, setValue } = useFormContext();

  const value = watch(TARGET_AMOUNT_RADIO_NAME);
  const targetValue = watch(name);

  const {
    'agent-euc-target-equity-field-v2': { enabled },
  } = useFlags(['agent-euc-target-equity-field-v2']);

  useEffect(() => {
    setFocus(value === '0');
  }, [value]);

  if (!enabled) {
    return (
      <Inputs.Currency
        name={name}
        label={label || 'Target Equity Unlock Amount'}
        placeholder="Enter target amount"
        tooltip="The amount of equity your client needs to unlock from their home to put towards their next purchase."
        testId={props['data-test']}
        optional
        {...props}
      />
    );
  }

  return (
    <Inputs.RadioGroup
      name={TARGET_AMOUNT_RADIO_NAME}
      label={label || <DefaultLabel />}
      {...props}
    >
      <Inputs.RadioItem
        value="0"
        alignItems="center"
        inputProps={{
          onClick: () => {
            if (!targetValue) {
              setValue(name, '0');
            }
          },
        }}
      >
        <Paragraph variant="text-small">
          I know my client's Target Equity Unlock Amount
        </Paragraph>
      </Inputs.RadioItem>
      {value === '0' && (
        <SlideDown width="100%">
          <Inputs.Currency
            name={name}
            placeholder="Enter target equity amount, e.g.,$200,000"
            isFocused={focus}
            data-test={props['data-test'] || props.inputTestId}
          />
        </SlideDown>
      )}

      <Inputs.RadioItem
        value="1"
        alignItems="center"
        inputProps={{
          onClick: () => {
            setValue(name, null);
          },
        }}
      >
        <Paragraph variant="text-small">I'm not sure</Paragraph>
      </Inputs.RadioItem>
      {value === '1' && (
        <SlideDown
          p="12px"
          gap="8px"
          bgcolor="#F8F8FB"
          justifyItems="flex-start"
          maxWidth={{ xs: '100%', md: '393px' }}
        >
          <Icon size={16} type="textBubble" />
          <Paragraph variant="text-xsmall">
            By providing a Target Equity Unlock Amount, you increase your
            client's chance of expedited approval.
          </Paragraph>
        </SlideDown>
      )}
    </Inputs.RadioGroup>
  );
};
