import { Box, Paragraph } from '@hl-portals/ui';

export type Assumption = {
  label: string;
  value: React.ReactNode;
  description?: string;
};

export const AssumptionItem = ({ label, value, description }: Assumption) => (
  <Box justifyContent="space-between">
    <Box flexDirection="column">
      <Paragraph variant="text-small" mb={description && '4px'}>
        {label}
      </Paragraph>
      {description && (
        <Paragraph variant="text-xsmall" color="coolGray2">
          {description}
        </Paragraph>
      )}
    </Box>
    <Box fontSize="14px" textAlign="right">
      {value}
    </Box>
  </Box>
);
