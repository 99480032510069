import { PropsWithChildren } from 'react';

import { Box } from '@hl-portals/ui';

export const Content = ({ children }: PropsWithChildren) => (
  <Box
    bgcolor="white"
    flex="1"
    p={{ xs: '32px 16px', sm: '80px 128px 128px 128px' }}
  >
    {children}
  </Box>
);
