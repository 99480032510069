import { PropsWithChildren } from 'react';

import { Box, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { BBYSLogo } from './BBYSLogo';

export const Sidebar = ({ children }: PropsWithChildren) => (
  <Box
    flexDirection="column"
    bgcolor={theme.colors.mistBlue}
    p={{ xs: '32px 16px', md: '80px' }}
    flex=".75"
    maxWidth={{ xs: 'auto', md: '600px' }}
    minHeight={{ xs: 'auto', md: 'calc(100vh - 67px)' }}
  >
    <BBYSLogo variant="heading-4" mb={{ xs: '24px', md: '40px' }} />
    <Paragraph
      variant="heading-2"
      mb="12px"
      fontSize={{ xs: '24px', md: '30px' }}
    >
      Equity Unlock Calculator
    </Paragraph>
    <Paragraph lineHeight="180%">
      Estimate how much equity you can unlock for your clients with Buy Before
      You Sell
    </Paragraph>

    {children && <Box mt="40px">{children}</Box>}
  </Box>
);
