import html2canvas from 'html2canvas';
import styled from 'styled-components';

import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  FadeIn,
  Icon,
  Layout,
  Paragraph,
  ProgramFooter,
  ResponsiveDisplay,
} from '@hl-portals/ui';

// import '@hl-portals/ui';
import { theme } from '@hl-portals/constants';

import { CalculateEquityData } from '@hl-portals/hooks';

import { useUser } from '../../../contexts/Auth';
import { Report } from './Report';
import type { PropertyInfo } from './types';

const TransparentButton = styled(Button)`
  background: transparent;
`;

type ResultProps = {
  flavor: 'agent' | 'lender';
  propertyInfo: PropertyInfo;
  equityData: CalculateEquityData;
  goBack: () => void;
};

export const Result = ({
  flavor = 'agent',
  propertyInfo,
  equityData,
  goBack,
}: ResultProps) => {
  const printRef = useRef<HTMLDivElement | null>(null);
  const [downloading, setDownloading] = useState(false);
  const { user } = flavor == 'agent' ? useUser() : { user: null };
  const { fallback } = equityData;
  const onDownloadClick = () => {
    setDownloading(true);
  };

  const downloadImage = async () => {
    const canvas = await html2canvas(printRef.current);
    const link = canvas.toDataURL('image/png', 1.0);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = link;
    a.download = 'estimated_equity_unlock_range.png';
    a.click();
    setDownloading(false);
  };

  useEffect(() => {
    if (downloading) {
      downloadImage();
    }
  }, [downloading]);

  const { address, unit, city, state, postalCode } = propertyInfo;
  const submitLink = `/trade-in-submission?pAddress=${address}&pUnit=${unit}&pCity=${city}&pState=${state}&pPostalCode=${postalCode}&source=calculator`;

  return (
    <>
      <FadeIn flexDirection="column" flexGrow="1">
        <Box flexDirection="column" alignItems="center">
          <Box
            py="40px"
            px={{ xs: '0', md: '40px' }}
            maxWidth={{ xs: '100%', md: '704px' }}
            flexDirection="column"
            flexGrow="1"
            ref={printRef}
          >
            {fallback && (
              <Box m="16px 16px 0" bgcolor={theme.colors.darkBlue}>
                <Paragraph variant="text-small" color="white" p="16px">
                  We couldn't find information on the property, the range is
                  based solely on the Agent's Home Value Estimate.
                </Paragraph>
              </Box>
            )}
            <Report
              flavor={flavor}
              user={user}
              propertyInfo={propertyInfo}
              equityData={equityData}
            />
          </Box>
        </Box>
        <ProgramFooter fixedFooter={true}>
          <Layout
            mt="0"
            py="16px"
            flexDirection="row"
            maxWidth={{ xs: '100%', md: '1040px' }}
          >
            <Box>
              <Button
                color="tertiary"
                iconLeft={<Icon type="angleLeft" size={20} />}
                onClick={() => goBack()}
                p={{ xs: '8px 16px', md: '0 16px' }}
              >
                Back
              </Button>
            </Box>
            <Box flexDirection="row" gap="16px">
              <TransparentButton
                display="flex"
                color="secondary"
                size="small"
                alignItems="center"
                onClick={() => onDownloadClick()}
                p={{ xs: '8px 16px', md: '0 16px' }}
                data-test="equity-download-btn"
              >
                <Icon
                  mr={{ xs: '0px', md: '8px' }}
                  type="downloadLine"
                  fill="electricBlue"
                />
                <ResponsiveDisplay desktop="Download Report" mobile={null} />
              </TransparentButton>
              {flavor == 'agent' && (
                <Button
                  as="a"
                  href={submitLink}
                  size={{ xs: 'large', md: 'small' }}
                  width={{ xs: '100%', md: 'auto' }}
                  data-test="client-submit-btn"
                >
                  Submit Client
                </Button>
              )}
            </Box>
          </Layout>
        </ProgramFooter>
      </FadeIn>
    </>
  );
};
