import { FadeIn, Paragraph, Spinner } from '@hl-portals/ui';

export const GenericLoading = (props) => (
  <FadeIn
    width="100%"
    height="calc(100vh - 80px)"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <Spinner xl></Spinner>
    <Paragraph variant="text-small" mt="24px">
      Loading...
    </Paragraph>
  </FadeIn>
);
