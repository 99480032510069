import { Box, Divider, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { formatPhoneNumber } from '@hl-portals/helpers';
import { config } from '@hl-portals/helpers';
import { formatCurrency } from '@hl-portals/helpers';

import {
  CalculateEquityData,
  useQRCode,
  useScreenSize,
} from '@hl-portals/hooks';

import type { Assumption } from './Assumption';
import { AssumptionItem } from './Assumption';
import { BBYSLogo } from './BBYSLogo';
import { PropertyInfo } from './types';

const lowEndHighEndRange = ({
  low_end_range,
  high_end_range,
}: {
  low_end_range: number;
  high_end_range: number;
}) => {
  if (high_end_range <= 0) {
    return formatCurrency({ value: 0 });
  }

  const low_range_value = low_end_range < 0 ? 0 : low_end_range;
  return `${formatCurrency({ value: low_range_value })} - ${formatCurrency({
    value: high_end_range,
  })}`;
};

type ReportProps = {
  flavor: 'agent' | 'lender';
  user?: any;
  propertyInfo: PropertyInfo;
  equityData: CalculateEquityData;
};

export const Report = ({
  flavor,
  user,
  propertyInfo,
  equityData,
}: ReportProps) => {
  const { isMobile } = useScreenSize();
  const qrCode = useQRCode(
    `${config.appHost}/agents/${user?.agent?.slug}?utm_source=web_qr`
  );
  const { high_end_range, low_end_range } = equityData;
  const {
    address,
    unit,
    city,
    state,
    postalCode,
    estimatedHomeValue,
    estimatedMortgageBalance,
  } = propertyInfo;

  const lowHighRange = lowEndHighEndRange({
    low_end_range: low_end_range,
    high_end_range: high_end_range,
  });
  const assumptions: Assumption[] = [
    {
      label: 'Property Address',
      value: (
        <Box flexDirection={{ xs: 'column', md: 'row' }}>
          <Paragraph variant="text-small" mr={{ xs: '0', md: '4px' }}>
            {address}
            {unit ? `, #${unit}` : ''},
          </Paragraph>
          <Paragraph variant="text-small">
            {city}, {state} {postalCode}
          </Paragraph>
        </Box>
      ),
    },
    {
      label:
        flavor == 'lender'
          ? "Lender's Estimated Home Value"
          : "Agent's Estimated Home Value ",
      value: <>{formatCurrency({ value: estimatedHomeValue })}</>,
    },
    {
      label: 'Estimated Mortgage Balance',
      value: (
        <>
          {formatCurrency({
            value: equityData.estimated_loan_payoff || estimatedMortgageBalance,
          })}
        </>
      ),
      description:
        estimatedMortgageBalance || equityData.estimated_loan_payoff
          ? `Value provided by ${
              estimatedMortgageBalance || equityData.estimated_loan_payoff
                ? flavor == 'lender'
                  ? 'Lender'
                  : user?.agent?.full_name
                : 'our data services'
            }`
          : 'Assuming property is free and clear.',
    },
  ];

  return (
    <Box flexDirection="column" p="16px">
      <Box
        flexDirection="column"
        borderRadius="8px"
        border={`1px solid ${theme.colors.coolGray5}`}
        bgcolor="white"
        overflow="hidden !important"
        mb="24px"
      >
        <Box
          bgcolor={theme.colors.lightBlue}
          flexDirection="column"
          p="40px 32px 0px"
        >
          <Box justifyContent="space-between" alignItems="top" mb="16px">
            <Box flexDirection="column">
              <BBYSLogo variant="text-bold" />
              <Paragraph
                variant="text-xsmall"
                mt="24px"
                textTransform="uppercase"
              >
                Equity Unlock Range:
              </Paragraph>
              <Paragraph
                fontSize="32px"
                variant={isMobile ? 'heading-3' : 'heading-2'}
                mb="12px"
                data-test="equity-range"
              >
                {lowHighRange}
              </Paragraph>
            </Box>
            {flavor == 'agent' && (
              <Box flexDirection="column" alignItems="center">
                <Box
                  width="100px"
                  height="100px"
                  justifyContent="center"
                  alignItems="center"
                  border="1px solid #DBDFE6"
                  borderRadius="8px"
                  overflow="hidden !important"
                >
                  <img width="100%" src={qrCode} />
                </Box>
                <Paragraph variant="text-micro" color="coolGray2" mt="4px">
                  Scan code to learn more
                </Paragraph>
              </Box>
            )}
          </Box>
        </Box>
        <Box p="24px" flexDirection="column">
          <Box flexDirection="column" gap="16px" width="100%">
            <Paragraph variant="text-small-bold">Assumptions:</Paragraph>
            {assumptions.map((assumption) => (
              <AssumptionItem key={assumption.label} {...assumption} />
            ))}
          </Box>
          {flavor == 'agent' && (
            <Box flexDirection="column">
              <Divider my="16px" />
              <Paragraph variant="text-small-bold" mb="16px">
                For questions, contact:
              </Paragraph>
              <Box flexDirection="row">
                <Box flexDirection="column" gap="4px">
                  <Paragraph variant="text-bold">
                    {user.agent.full_name}
                  </Paragraph>
                  <Paragraph variant="text-small">
                    {user.agent.email1}
                  </Paragraph>
                  <Paragraph variant="text-small">
                    {formatPhoneNumber(user.agent.phone_mobile)}
                  </Paragraph>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box flexDirection="column" gap="8px">
        <Paragraph color="coolGray2" variant="text-xsmall">
          This range is meant to be a guide/estimate of the equity unlock amount
          based off of your input and our system's initial review of your
          property. This is not a commitment to lend and is not a formal
          approval. Final numbers will be provided after further review and
          additional information is gathered. Minimums may apply.
        </Paragraph>
      </Box>
    </Box>
  );
};
